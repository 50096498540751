import type { IndustryOptionsObject } from "../../translation-keys/industry-option-keys.js";

const FormIndustryOptions: IndustryOptionsObject = {
  "Active Pharmaceutical Ingredients": "Active Pharmaceutical Ingredients",
  Aerospace: "Aerospace",
  "Animal Health": "Animal Health",
  Automotive: "Automotive",
  "Banking/Finance/Insurance": "Banking/Finance/Insurance",
  "Biotechnology - Med Device": "Biotechnology - Med Device",
  "Biotechnology - Pharma": "Biotechnology - Pharma",
  "Blood & Biologics": "Blood & Biologics",
  Chemicals: "Chemicals",
  "Clinical Research": "Clinical Research",
  "Combination Device": "Combination Device",
  "Combination Pharma": "Combination Pharma",
  Communications: "Communications",
  "Computer Equipment/Peripherals": "Computer Equipment/Peripherals",
  "Construction/Architecture/Engineering":
    "Construction/Architecture/Engineering",
  "Consulting Services": "Consulting Services",
  "Contract Manufacturing - Med Device": "Contract Manufacturing - Med Device",
  "Contract Manufacturing - Pharma": "Contract Manufacturing - Pharma",
  "Contract Research Organization (CRO)":
    "Contract Research Organization (CRO)",
  Cosmetics: "Cosmetics",
  "Diagnostics - Medical": "Diagnostics - Medical",
  "Dietary Supplements": "Dietary Supplements",
  Distribution: "Distribution",
  Education: "Education",
  Electronics: "Electronics",
  Energy: "Energy",
  Environmental: "Environmental",
  Food: "Food",
  "General Manufacturing": "General Manufacturing",
  Government: "Government",
  Healthcare: "Healthcare",
  Hospital: "Hospital",
  "Laboratory - Biologics": "Laboratory - Biologics",
  "Laboratory - ISO": "Laboratory - ISO",
  "Laboratory - MD": "Laboratory - MD",
  "Laboratory - Other": "Laboratory - Other",
  "Lawyer / Law Firm": "Lawyer / Law Firm",
  "Life Science Supplier": "Life Science Supplier",
  Media: "Media",
  "Medical Device": "Medical Device",
  "Medical Marijuana": "Medical Marijuana",
  Nuclear: "Nuclear",
  "Oil & Gas / Mining": "Oil & Gas / Mining",
  Packaging: "Packaging",
  Pharmaceutical: "Pharmaceutical",
  Publishing: "Publishing",
  Retail: "Retail",
  "Software/Hardware Supplier": "Software/Hardware Supplier",
  "Student/University": "University",
  Technology: "Technology",
  "Transportation/Logistics": "Transportation/Logistics",
  "Travel/Hospitality/Restaurant": "Travel/Hospitality/Restaurant",
};

export default FormIndustryOptions;
